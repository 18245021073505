<template>
  <div id="preview">
    <v-card>
      <v-card-title>
        <span class="headline">{{ pageDescription }}</span>
      </v-card-title>

      <v-card-text>

        <v-data-table
          class="elevation-1"
          loading-text="Loading... Please wait"
          v-model="selected"
          :loading="searchFlag"
          :search="search"
          :headers="recordData.SubscribtionsHeader"
          :items="recordData.Subscribtions"
          :items-per-page="50"
          item-key="orders_details_id"
          :single-select="false"
          :show-select="false"
          :disable-sort="true"
          :hide-default-footer="true"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
        >
          <template v-slot:item.ActiveStatusTxt="{ item }">
            <v-chip
              :color="getActiveStatusColor(item.ActiveStatusTxt)"
              draggable
              dark
              >{{ item.ActiveStatusTxt }}</v-chip
            >
          </template>
          <template v-slot:item.ProfilePic="{ item }">
            <img width="100" height="100" :src="item.ProfilePic" />
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      rows: [],
      records: [],

      selected: [],
      searchFlag: false,
      search: '',

      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    rows: function () {
      console.log("watch rows");
      var n1 = this.rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.records = this.rows[0];
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    validate() {
      console.log("validate is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.addRecordDetails();
      } else {
        console.log("some errors occured");
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      //   this.getAwardsDetails();
    },
    getAwardsDetails() {
      console.log("getAwardsDetails called");
      var awardsId = this.awardsId;
      console.log("awardsId=" + awardsId);
      var list_url = "api/natcon/awards_lom/single";
      var where = {
        UI: 2,
        where: {
          AwardsId: awardsId,
        },
      };
      console.log("list_url=" + list_url + ", where=" + JSON.stringify(where));
      this.getTableData("rows", list_url, where);
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
