<template>
  <div id="lom-list">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5"> <h2>Payment Receipts</h2> </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Search the payment receipts by the filter</h4></v-card-subtitle
      >

      <v-container class="py-0" v-if="FloatingButtonFlag">
        <v-speed-dial
          v-model="fab"
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
          :fixed="true"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
          <v-btn fab dark small color="indigo" @click.prevent="addRecord">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
        </v-speed-dial>
      </v-container>

      <v-dialog v-model="previewRecordPrompt" max-width="75%">
        <subscription-details
          pageDescription="Subscription Details"
          :previewRecordPrompt="previewRecordPrompt"
          @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
          :recordData="selectedData"
          v-if="previewRecordPrompt"
        ></subscription-details>
      </v-dialog>

      <v-dialog eager v-model="LomDataPreviewFlag" v-if="LomDataPreviewFlag">
        <lom-information
          :PageTitle="LomDataPageTitle"
          :recordData="LomDataParams"
          @hideDialog="hideLomDataPreview"
          v-if="LomDataPreviewFlag"
        ></lom-information>
      </v-dialog>

      <v-dialog v-model="addRecordPrompt" persistent max-width="600px">
        <add-online-subscription-payment
          pageDescription="Add Online Subscription Payment"
          :addRecordPrompt="addRecordPrompt"
          @hideAddRecordPrompt="hideAddRecordPrompt"
          v-if="addRecordPrompt"
        ></add-online-subscription-payment>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>* Zone</label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label> LO <small>(Optional)</small> </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>From Date</label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="FromDate"
                      label="From Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="FromDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>To Date</label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="ToDate"
                      label="To Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ToDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Payment Id <small>(optional)</small></h6>
                <v-text-field
                  v-model="PaymentId"
                  :rules="PaymentIdRules"
                  placeholder="Payment Id"
                  hint="Enter razorpay payment id"
                  persistent-hint
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
                <v-btn
                  v-if="RoleId == 2"
                  @click.prevent="addRecordPrompt = true"
                  color="#8b008b"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Add Online Payment
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} records found.</h3>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="ResultFlag && rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="!ExcelDownloadFlag"
                @click.prevent="generateReportExcel"
                :loading="ExcelLoadingFlag"
                target="_blank"
                color="#ff7f50"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                Generate Report
              </v-btn>
              <v-btn
                v-if="ExcelFileName != '' && ExcelDownloadFlag == 1"
                :href="ExcelFileName"
                target="_blank"
                color="#2fb65a"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
              >
                <v-icon dark> mdi-download </v-icon> Download Report
              </v-btn>
              <!-- <json-excel
                v-if="rows.length > 0 && ExcelDownloadFlag == 1"
                :name="ExcelFileName"
                :data="rows"
                :fields="ExcelFields"
                worksheet="My Worksheet"
              >
                <v-btn
                  color="#008000"
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Download
                </v-btn>
              </json-excel> -->
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :loading="SearchFlag"
                loading-text="Loading... Please wait"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="50"
                :search="search"
                item-key="MemberId"
                :single-select="false"
                :show-select="false"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.ReceiptFilePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.ReceiptFilePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-pdf </v-icon>
                      </v-btn>
                    </template>
                    <span> Download </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="#00f"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="showLomDataPreview(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-eye
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.ActiveStatusTxt == 2"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="green"
                        @click="addTallyRecords(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.TallyAddFlag"
                      >
                        mdi-finance
                      </v-icon>
                    </template>
                    <span> Add to Tally </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteTallyRecords(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.TallyDeleteFlag"
                      >
                        mdi-finance
                      </v-icon>
                    </template>
                    <span> Delete from Tally </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import SubscriptionDetails from "@/view/pages/erp/reports/SubscriptionDetails.vue";
import AddOnlineSubscriptionPayment from "@/view/pages/erp/payment/AddOnlineSubscriptionPayment.vue";

export default {
  mixins: [common],
  components: {
    SubscriptionDetails,
    AddOnlineSubscriptionPayment,
  },
  data() {
    return {
      valid1: true,
      valid2: true,

      FloatingButtonFlag: false,
      LoadingFlag: false,
      ResultFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: "",

      OverlayLoadingFlag: false,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [
        // (v) => !!v || "LOM is required"
      ],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PaymentId: "",
      PaymentIdRules: [],

      JciYearCode: "",

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      selectedData: {},

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      LomDataPageTitle: "",
      LomDataPreviewFlag: false,
      LomDataParams: {},

      RoleId: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.LoadingFlag = false;
      this.AreaCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      // this.JciYearCodeOptionsLoading = true;
      // this.getYearCodeOptions("JciYearCode", "JciYearCodeOptions");
      // this.ZoneCodeOptionsLoading = true;
      // this.getZoneCodeOptions("ZoneCode", "ZoneCodeOptions");
      this.getZoneCodeOptions();
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "AreaCode";
        var selectbox1_destination = "AreaCodeOptions";
        var selectbox1_url = "api/year-wise-lom/area-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.LomCodeOptionsLoading = true;
      var selectbox1_source = "LomCode";
      var selectbox1_destination = "LomCodeOptions";
      var selectbox1_url = "api/year-wise-lom/lom-options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        ZoneCode: this.ZoneCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");
      this.getTableRecords();
      // this.generateReportExcel();
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      var validate1 = this.$refs.form2.validate();
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.SearchFlag = true;
        this.rows = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/subscription/receipt/lists";
        var upload = {
          UserInterface: 2,
          ZoneCode: this.ZoneCode,
          LomCode: this.LomCode,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
          PaymentId: this.PaymentId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableColumns1 = records.TableHeader;
            // thisIns.ExcelFields = records.ExcelFields;
            // thisIns.ExcelFileName = records.ExcelFileName;
            thisIns.ExcelDownloadFlag = false;

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.rows = records.TableData;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      var validate1 = this.$refs.form2.validate();
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/receipt/excel/export";
        var upload = {
          UserInterface: 2,
          ZoneCode: this.ZoneCode,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    showLomDataPreview(tr) {
      console.log("showLomDataPreview called");
      console.log({ tr });
      this.LomDataPageTitle = tr.LomName + " information for the current year";
      this.LomDataPreviewFlag = true;
      this.LomDataParams = {
        UserInterface: 1,
        LomCode: tr.LomId,
      };
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    hideLomDataPreview() {
      console.log("hideLomDataPreview called");
      this.LomDataPreviewFlag = false;
    },
    addTallyRecords(tr) {
      console.log("addTallyRecords is called");
      console.log({ tr });

      var OrdersId = tr.OrdersId;
      console.log({ OrdersId });

      if (OrdersId > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/tally/add-dues";
        var upload = {
          UserInterface: 1,
          Order: OrdersId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.OverlayLoadingFlag = true;

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            thisIns.OverlayLoadingFlag = false;

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    deleteTallyRecords(tr) {
      console.log("deleteTallyRecords is called");
      console.log({ tr });

      var OrdersId = tr.OrdersId;
      console.log({ OrdersId });

      if (OrdersId > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/tally/delete-dues";
        var upload = {
          UserInterface: 1,
          Order: OrdersId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.OverlayLoadingFlag = true;

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            thisIns.OverlayLoadingFlag = false;

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.getTableRecords();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  mounted() {
    // this.searchForm();
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentJciYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    // RoleId = 4;
    this.RoleId = RoleId;

    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);

    this.FromDate = todayDate;
    this.ToDate = todayDate;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>